import Head from 'next/head'
import router from 'next/router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import GridSvg from 'assets/svg/not-found-background.svg'
import Button from 'components/Button'
import { Body } from 'components/Text'
import { FlexDivCol } from 'components/layout/flex'
import ROUTES from 'constants/routes'
import EnabledChains from 'sections/homepage/EnabledChains'
import HomeLayout from 'sections/shared/Layout/HomeLayout'
import media from 'styles/media'

const NotFoundPage = () => {
	const { t } = useTranslation()

	return (
		<>
			<Head>
				<title>{t('not-found.page-title')}</title>
			</Head>
			<Layout marketsMarquee={false}>
				<Content rowGap="12px">
					<Title>{t('not-found.title')}</Title>
					<Subtitle>{t('not-found.subtitle')}</Subtitle>
					<div>
						<StyledButton noOutline size="medium" onClick={() => router.push(ROUTES.Home.Root)}>
							{t('not-found.go-to-home-page')}
						</StyledButton>
					</div>
				</Content>
				<EnabledChains />
				<BackgroundContainer>
					<GridSvg className="bg" objectfit="cover" layout="fill" width="100%" height="100%" />
				</BackgroundContainer>
			</Layout>
		</>
	)
}

const BackgroundContainer = styled.div`
	position: absolute;
	right: 0px;
	top: 50px;
	height: calc(100% - 100px);
	z-index: 1;
	${media.lessThan('md')`
		width: 100%;
		height: auto;
	`}
`

const Content = styled(FlexDivCol)`
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	font-weight: 400;
	font-feature-settings: 'tnum';
	padding: 0 48px;
	margin: 60px 0 0 60px;
	text-align: left;
	z-index: 2;
	position: inherit;
	max-width: 500px;

	${media.lessThan('md')`
		position: absolute;
		padding: 0 20px 0 0;
		margin: 0;
		bottom: 150px;
	`}
`

const Title = styled.h1`
	font-size: 120px;
	margin: 0;
	line-height: normal;
	${media.lessThan('md')`
		font-size: 80px;
	`}
`

const Subtitle = styled(Body)`
	margin: 0;
	font-size: 16px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
	line-height: normal;
	${media.lessThan('md')`
		font-size: 14px;
	`}
`

const StyledButton = styled(Button)`
	background-color: transparent;
	border-radius: 4px;
	&:hover {
		background-color: transparent;
	}
`
const Layout = styled(HomeLayout)`
	& main {
		overflow: hidden;
	}
`

export default NotFoundPage
